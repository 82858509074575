/* MonthlySchedule.css */

.shift-droppable {
  min-height: 50px;
  padding: 4px;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border: 1px dashed #ccc;
  background-color: #f8f9fa;
  margin-bottom: 10px;
  min-width: 100px; /* Adjust as needed */
}

.shift-droppable.dragging-over {
  background-color: #e0f7fa;
}

.doctor-badge {
  margin: 2px;
  cursor: grab;
}

.doctor-badge.dragging {
  opacity: 0.5;
}
